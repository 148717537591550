import React from 'react'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'
import { renderRichText } from "gatsby-source-contentful/rich-text"


import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Cta from '../../components/sections/Cta';
import Emailer from '../../components/sections/Emailer';
import Hero from '../../components/sections/Hero'
import Breadcrumbs from '../../components/sections/Breadcrumbs';

export default ({data}) => {
    const {page} = data
    return (
        <Layout>
            <SEO 
              title={page.seoInfo.title}
              description={page.seoInfo.metaDescription.metaDescription}
              robots={page.seoInfo.metaRobots}
              ogDescription={page.seoInfo.openGraphDescription}
              twitterDescription={page.seoInfo.twitterCardDescription}
              twitterTitle={page.seoInfo.twitterCardTitle}
              twitterImage={page.seoInfo.twitterCardImage}
              ogTitle={page.seoInfo.openGraphTitle}
              twitterImage={page.seoInfo.twitterImage}
              ogImage={page.seoInfo.openGraphImage.localFile.url}
            />
            <Hero image={page.backgroundImage.fluid} sub="About" title={page.title} height="small"/> 
            <Breadcrumbs />
            <div className="mx-auto container px-4">
                <div className="mb-16 md:mb-24 flex flex-wrap">
                    <div className="w-full md:w-1/3 pr-24 mb-5 md:mb-0">
                        <h3 className="text-primary mb-2">{page.introSub}</h3>
                        <h2 className="mb-0 font-extrabold uppercase">{page.introTitle}</h2>
                    </div>
                    <div className="w-full md:w-2/3">
                        {page.introTextLarge.introTextLarge}
                    </div>
                </div>
                <div className="mb-8 md:mb-24">
                    {page.secondImage.fluid && <Img className="mb-10" fluid={page.secondImage.fluid} />}
                    {page.content && renderRichText(page.content)}
                </div>
                <Cta />
                <Emailer />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($id: String!) {
        page: contentfulAbout(id: {eq: $id}) {
            slug
            id
            title
            introTitle
            introSub
            introTextLarge {
              introTextLarge
            }
            content {
              raw
            }
            secondImage {
                fluid(maxWidth: 1500, quality: 70) {
                    ...GatsbyContentfulFluid
                }
            }
            backgroundImage {
                fluid(maxWidth: 1920, quality: 70) {
                    ...GatsbyContentfulFluid
                }
            }
            seoInfo {
              canonicalUrl
              metaDescription {
                metaDescription
              }
              metaRobots
              openGraphDescription
              openGraphTitle
              title
              twitterCardImage
              twitterCardDescription
              twitterCardTitle
              openGraphImage {
                localFile {
                  url
                }
              }
            }
        }
    }
`